<template>
  <b-container>
    <metabase :code="456" />
  </b-container>
</template>

<script>
import {
    BContainer,
} from 'bootstrap-vue';
import Metabase from '../components/Metabase.vue';

export default {
    components: {
        BContainer,
        Metabase,
    },
};
</script>
